import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';
//funcionalidades

Vue.use(VueRouter)

const routes = [
    {
        name: 'Unauthorized',
        path: '/unauthorized',
        meta: {
            title: 'Sem Autorização',
            unauthorized: true,
        },
        component: () => import('@/views/Unauthorized')
    },
    {
        name: 'LoginUsuario',
        path: '/login',
        meta: {
            loginPage: true
        },
        component: () => import('@/views/Login')
    },  
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async (to, from, next) => {
    await store.restored;
    const {meta} = to;

    function isGoingToLoginPage() {
        return meta.hasOwnProperty('loginPage') && meta.loginPage;
    }

    function isGoingToUnauthorizedPage() {
        return meta.hasOwnProperty('unauthorized') && meta.unauthorized;
    }

    async function handleIsAuthenticated() {
        const defaultHomePage = await store.dispatch('auth/getDefaultHomePage');
        if (isGoingToLoginPage()) {
            next({
                name: defaultHomePage
            });
        } else {
            const authorizedLinks = [...store.getters['auth/authorizedLinks']];
            if (isGoingToUnauthorizedPage()) {
                next();
            } else {
                if (authorizedLinks.indexOf(to.path) > -1) {
                    next();
                } else {
                    if (to.path === '/') {
                        next({
                            name: defaultHomePage
                        })
                    } else {
                        next('/unauthorized')
                    }
                }
            }
        }
    }

    async function handleNotAuthenticated() {
        if (isGoingToLoginPage()) {
            next();
        } else {
            const defaultLoginPage = await store.dispatch('auth/getDefaultLoginPage');
            next({
                name: defaultLoginPage
            })
        }
    }

    if (store.getters['auth/isAuthenticated']) {
        await handleIsAuthenticated();
    } else {
        await handleNotAuthenticated();
    }

})

export default router
