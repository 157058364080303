import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import device from 'vue-device-detector-js'
import './plugins/axios';
import './plugins/validators';
import './plugins/toastification';
import './plugins/async-computed';
import './plugins/router-sync';
import './plugins/mask';
import './plugins/moment';
import './plugins/sweet-alert';
import './plugins/utils';
import './plugins/shortkey';

Vue.use(device);

Vue.config.productionTip = process.env.NODE_ENV === 'production';

// Método global que você deseja adicionar
Vue.mixin({
  methods: {
    isProduction() {
      return process.env.NODE_ENV === "production"
    }, 
    ambiente() {
      return process.env.VUE_APP_AMBIENTE
    },   
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
